<template>
  <div :class="$style.home">
    <p :class="$style.title">任务发放</p>
    <a-table :columns="columns" :data-source="data" :loading="loading" :pagination="false" row-key="task_code">
      <template #status="item">
        <BjTag :type="item.status === 0 ? 'default' : item.status === 1 ? 'primary' : 'danger'">
          {{ item.status | state }}
        </BjTag>
      </template>
    </a-table>
    <div class="pagination">
      <a-pagination
        :current="page"
        show-size-changer
        :show-total="total => `共 ${total} 条`"
        :page-size.sync="pageSize"
        :total="total"
        @showSizeChange="onShowSizeChange"
        @change="onPageChange"
      />
    </div>
    <bj-modal title="设置" :visible="visible.detail" :body-style="{ height: '400px' }" @ok="setOk" @cancel="setCancel">
      <BjForm :class="$style.modal">
        <a-row>
          <a-col class="line-40" span="5">
            <span class="required">*</span>
            奖励分值
          </a-col>
          <a-col span="19">
            <BjInput v-model="edit.amount" addon-after="分" placeholder="请输入任务完成后的奖励分值" />
            <p class="tip mt-10 mb-20">用户完成相关任务后会触发奖励机制，积分会发放到用户的积分账户。</p>
          </a-col>
        </a-row>
        <a-row>
          <a-col class="line-40" span="5">
            <span class="required">*</span>
            任务频次
          </a-col>
          <a-col span="19">
            <BjInput
              v-model="edit.cycle_value"
              addon-after="次"
              :class="$style.input"
              :disabled="!edit.editable"
              placeholder="请输入任务频次"
            >
              <a-select slot="addonBefore" v-model="edit.cycle" :disabled="!edit.editable" style="width: 72px">
                <a-select-option :value="1"> 合计 </a-select-option>
                <a-select-option :value="0"> 每日 </a-select-option>
                <i slot="suffixIcon" class="ri-arrow-down-s-line suffix" />
              </a-select>
            </BjInput>
            <p class="tip mb-20">用户完成相关任务后会触发奖励机制，积分会发放到用户的积分账户。</p>
          </a-col>
        </a-row>
        <a-row>
          <a-col class="line-40" span="5">
            <span class="required">*</span>
            开启状态
          </a-col>
          <a-col class="line-40" span="19">
            <BjSwitch v-model="edit.status" size="default" />
          </a-col>
        </a-row>
      </BjForm>
    </bj-modal>
  </div>
</template>

<script>
import _ from 'lodash'

import { integralManageService } from '@/service'

const service = new integralManageService()

export default {
  name: 'provide',
  filters: {
    state(val) {
      return val ? '已开启' : '未开启'
    },
  },
  data() {
    return {
      data: [],
      loading: false,
      page: 1,
      pageSize: 20,
      total: 0,
      visible: {
        detail: false,
      },
      edit: {
        task_code: null,
        amount: null,
        cycle: 0,
        cycle_value: null,
        status: 0,
      },
    }
  },
  computed: {
    columns() {
      const temp = {} // 当前重复的值,⽀持多列
      const mergeCells = (text, array, columns) => {
        let rowSpan = 0
        if (array.length == 1) {
          rowSpan = 1
        } else {
          if (text !== temp[columns]) {
            temp[columns] = text
            array.forEach(item => {
              if (item[columns] === temp[columns]) {
                rowSpan++
              }
            })
          }
        }
        return rowSpan
      }
      return [
        {
          title: '任务模块',
          dataIndex: 'task_module_name',
          customRender: text => {
            const obj = {
              children: text,
              attrs: {},
            }
            obj.attrs.rowSpan = mergeCells(text, this.data, 'task_module_name')
            return obj
          },
        },
        {
          title: '任务类型',
          dataIndex: 'task_type_name',
          customRender: text => {
            const obj = {
              children: text,
              attrs: {},
            }
            obj.attrs.rowSpan = mergeCells(text, this.data, 'task_type_name')
            return obj
          },
        },
        {
          title: '任务内容',
          dataIndex: 'task_content',
        },
        {
          title: '奖励分值',
          dataIndex: 'amount',
        },
        {
          title: '积分数量',
          customRender: item => (item.cycle ? '合计' : '每日' + item.cycle_value + '次'),
        },
        {
          title: '状态',
          scopedSlots: {
            customRender: 'status',
          },
        },
        {
          title: '管理',
          customRender: item => [
            <bj-link type='primary' onClick={() => this.onSet(item)}>
              设置
            </bj-link>,
          ],
        },
      ]
    },
  },
  created() {
    this.getList()
  },
  methods: {
    async getList() {
      try {
        const { data } = await service.getTask({
          page: this.page,
          page_size: this.pageSize,
        })
        this.data = data.record
        this.total = data.total
      } catch (e) {}
    },
    onSet(item) {
      this.visible.detail = true
      this.edit = _.cloneDeep(item)
    },
    async setOk() {
      try {
        if (!_.isInteger(Number(this.edit.amount))) {
          this.$message.error('请输入大于0的正整数')
          return
        }
        await service.editTask({ ...this.edit, amount: Number(this.edit.amount) })
        this.$message.success('修改成功')
        this.setCancel()
        this.getList()
      } catch (e) {}
    },
    setCancel() {
      this.visible.detail = false
    },
    onPageChange(page) {
      this.page = page
      this.getList()
    },
    onShowSizeChange() {
      this.page = 1
      this.getList()
    },
  },
}
</script>

<style lang="less" module>
.home {
  min-height: calc(100vh - 260px);

  .title {
    margin: 4px 0 14px 0;
    color: #000;
    font: 14px;
  }
}

.modal {
  :global {
    .ant-form-item {
      margin-bottom: 0;
    }

    .ant-select-arrow {
      top: 40%;
    }
  }
}
</style>
