<template>
  <div :class="$style.home">
    <div>
      <a-tabs default-active-key="1">
        <a-tab-pane key="1" tab="发放规则">
          <provide />
        </a-tab-pane>
        <a-tab-pane key="2" tab="回收规则" force-render>
          <recovery />
        </a-tab-pane>
        <a-tab-pane key="3" tab="基础规则">
          <cBase />
        </a-tab-pane>
      </a-tabs>
    </div>
  </div>
</template>

<script>
import base from './base'
import provide from './provide'
import recovery from './recovery'

export default {
  name: 'Home',
  components: {
    provide,
    recovery,
    cBase: base,
  },
  data() {
    return {}
  },
}
</script>

<style lang="less" module>
.home {
  & > div {
    padding: 20px;
    background-color: #fff;
  }
}
</style>
