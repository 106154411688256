<template>
  <div :class="$style.home">
    <div :class="$style.table">
      <a-table :columns="columns" :data-source="data" :loading="loading" :pagination="false" row-key="id">
        <template #status="item">
          <BjTag :type="item.status === 0 ? 'default' : item.status === 1 ? 'primary' : 'danger'">
            {{ item.status | state }}
          </BjTag>
        </template>
      </a-table>
    </div>

    <bj-modal
      title="设置积分有效期"
      :visible="visible.set"
      :body-style="{ height: '400px' }"
      @ok="setOk"
      @cancel="setCancel"
    >
      <BjForm :class="$style.modal">
        <a-row>
          <a-col class="line-40" span="5">
            <span class="required">*</span>
            有效期
          </a-col>
          <a-col span="19">
            <a-radio-group v-model="edit.key">
              <a-radio :value="0"> 永久有效 </a-radio>
              <a-radio :value="1">
                <div :class="$style.box">
                  <span>每年的</span>
                  <a-date-picker v-model="edit.value" :format="dateFormat" :class="$style.date" @change="onChange" />
                  <span>后过期</span>
                </div>
              </a-radio>
            </a-radio-group>
          </a-col>
        </a-row>
      </BjForm>
    </bj-modal>

    <bj-modal
      title="设置积分冻结期"
      :visible="visible.over"
      :body-style="{ height: '400px' }"
      @ok="overOk"
      @cancel="overCancel"
    >
      <BjForm :class="$style.modal">
        <a-row>
          <a-col class="line-40" span="5">
            <span class="required">*</span>
            冻结期
          </a-col>
          <a-col span="19">
            <a-radio-group v-model="edit.key">
              <a-radio :value="0"> 不冻结，及时到账 </a-radio>
              <a-radio :value="1">
                <BjInput v-model="edit.value" :class="$style.input" addon-after="后到账" placeholder="请设置天数" />
              </a-radio>
            </a-radio-group>
          </a-col>
        </a-row>
      </BjForm>
    </bj-modal>
  </div>
</template>

<script>
import moment from 'moment'

import { integralManageService } from '@/service'

const service = new integralManageService()

export default {
  name: 'recovery',
  data() {
    return {
      data: [],
      loading: false,
      visible: {
        set: false,
        over: false,
      },
      edit: {
        key: 0,
        value: 0,
      },
      dateFormat: 'MM-DD',
      detail: {},
    }
  },
  computed: {
    columns() {
      return [
        {
          title: '规则内容',
          dataIndex: 'name',
        },
        {
          title: '规则详情',
          dataIndex: 'detail',
        },
        {
          title: '管理',
          customRender: item => [
            <bj-link type='primary' onClick={() => this.onSet(item.id)}>
              设置
            </bj-link>,
          ],
        },
      ]
    },
  },
  created() {
    this.getList()
  },
  methods: {
    async getList() {
      try {
        this.loading = true
        const { data } = await service.getBase()
        this.detail = data
        this.data = [
          {
            name: '积分有效期',
            detail: data.expire_type ? data.expire : '永久有效',
            id: 1,
          },
          {
            name: '积分冻结期',
            detail: data.frozen ? `${data.frozen}天` : '不冻结',
            id: 2,
          },
        ]
        this.loading = false
      } catch (e) {}
    },
    onSet(id) {
      if (id === 1) {
        this.visible.set = true
        this.edit = {
          key: this.detail.expire_type ? 1 : 0,
          value: this.detail.expire_type ? moment('2022-' + this.detail.expire) : null,
        }
      }
      if (id === 2) {
        this.visible.over = true
        this.edit = {
          key: this.detail.frozen ? 1 : 0,
          value: this.detail.frozen ? this.detail.frozen : null,
        }
      }
    },
    async setOk() {
      try {
        await service.editBase({
          key: 'expire',
          value: this.edit.key ? this.edit.date : 0,
        })
        this.$message.success('修改成功')
        this.visible.set = false
        this.getList()
      } catch (e) {}
    },
    setCancel() {
      this.visible.set = false
    },
    onChange(date, dateString) {
      if (dateString === '02-29') {
        this.edit.value.add(1, 'd') // 不是每年都有29 那没法 http://momentjs.cn/docs/#/manipulating/add/
      }
      this.edit.date = dateString
    },
    async overOk() {
      try {
        await service.editBase({
          key: 'frozen',
          value: this.edit.key ? this.edit.value : 0,
        })
        this.$message.success('修改成功')
        this.visible.over = false
        this.getList()
      } catch (e) {}
    },
    overCancel() {
      this.visible.over = false
    },
  },
}
</script>

<style lang="less" module>
.home {
  min-height: calc(100vh - 260px);
  .table {
    //
  }
}

.modal {
  .box {
    display: flex;
    align-items: center;

    .date {
      width: 250px;
      margin: 0 10px;
    }
  }

  .input {
    width: 350px;
  }

  :global {
    .ant-form-item {
      margin-bottom: 0;
    }

    .ant-form label {
      display: flex;
      align-items: center;
      margin-bottom: 10px;
    }
  }
}
</style>
