<template>
  <div :class="$style.home">
    <div :class="$style.table">
      <p :class="$style.title">积分抵现</p>
      <a-table :columns="columns" :data-source="data" :loading="loading" :pagination="false" row-key="module_name">
        <template #status="item">
          <BjTag :type="item.status === 0 ? 'default' : item.status === 1 ? 'primary' : 'danger'">
            {{ item.status | state }}
          </BjTag>
        </template>
      </a-table>
    </div>

    <bj-modal title="设置" :visible="visible.set" :body-style="{ height: '400px' }" @ok="setOk" @cancel="setCancel">
      <BjForm :class="$style.modal">
        <a-row>
          <a-col class="line-40" span="5">
            <span class="required">*</span>
            抵现规则
          </a-col>
          <a-col span="19">
            <BjInput
              v-model="edit.integral_amount"
              addon-before="用户每使用"
              addon-after="积分可抵用1元"
              placeholder="请输入抵用分值"
            />
            <p class="tip mt-10 mb-20">请根据你的消费场景设置合适的抵用分值。</p>
          </a-col>
        </a-row>
        <a-row>
          <a-col class="line-40" span="5">
            <span class="required">*</span>
            订单门槛
          </a-col>
          <a-col span="19">
            <a-radio-group v-model="edit.order_type" @change="onChange">
              <a-radio :value="0"> 无门槛 </a-radio>
              <a-radio :value="1">
                <BjInput
                  v-model="edit.order_amount"
                  addon-before="单笔订单满"
                  addon-after="元可使用积分"
                  placeholder="请输入订单金额"
                />
              </a-radio>
            </a-radio-group>
          </a-col>
        </a-row>

        <a-row>
          <a-col class="line-40" span="5">
            <span class="required">*</span>
            抵用限制
          </a-col>
          <a-col span="19">
            <a-radio-group v-model="edit.limit_type" @change="onChange">
              <a-radio :value="0"> 无门槛 </a-radio>
              <a-radio :value="1">
                <BjInput
                  v-model="edit.limit_amount"
                  addon-before="单笔订单金额的"
                  addon-after="%"
                  placeholder="请输入订单金额的比例"
                />
              </a-radio>
              <a-radio :value="2">
                <BjInput
                  v-model="edit.limit_num"
                  addon-before="单笔订单最高可抵用"
                  addon-after="元"
                  placeholder="请输入最高抵现金额"
                />
              </a-radio>
            </a-radio-group>
          </a-col>
        </a-row>
        <a-row>
          <a-col class="line-40" span="5">
            <span class="required">*</span>
            开启状态
          </a-col>
          <a-col class="line-40" span="19">
            <BjSwitch v-model="edit.status" size="default" />
          </a-col>
        </a-row>
      </BjForm>
    </bj-modal>
  </div>
</template>

<script>
import _ from 'lodash'

import { integralManageService } from '@/service'

const service = new integralManageService()

export default {
  name: 'recovery',
  filters: {
    state(val) {
      return val ? '已开启' : '未开启'
    },
  },
  data() {
    return {
      data: [],
      loading: false,
      visible: {
        set: false,
      },
      edit: {
        module_id: null,
        integral_amount: null,
        order_amount: null,
        order_type: 0,
        limit_type: 0,
        limit_amount: null,
        limit_num: null, // 用来保存抵用限制的金额的 limit_amount先作为百分比
        status: 0,
      },
    }
  },
  computed: {
    columns() {
      return [
        {
          title: '回收模块',
          dataIndex: 'module_name',
        },
        {
          title: '抵现规则',
          dataIndex: 'integral_amount',
          customRender: text => `用户每使用${text}积分可抵用1元`,
        },
        {
          title: '订单门槛',
          dataIndex: 'order_amount',
          customRender: text => (text ? `${text}` : '无门槛'),
        },
        {
          title: '抵现限制',
          customRender: item => this.limit(item),
        },
        {
          title: '状态',
          scopedSlots: {
            customRender: 'status',
          },
        },
        {
          title: '管理',
          customRender: item => [
            <bj-link type='primary' onClick={() => this.onSet(item)}>
              设置
            </bj-link>,
          ],
        },
      ]
    },
  },
  created() {
    this.getList()
  },
  methods: {
    async getList() {
      try {
        const { data } = await service.getRecycle()
        this.data = data.record
      } catch (e) {}
    },
    limit(item) {
      switch (item.limit_type) {
        case 0:
          return '无限制'
        case 1:
          return '无限制'
        case 2:
          return `单笔订单最高可使用${item.limit_amount}分`
      }
    },
    onSet(item) {
      this.visible.set = true
      this.edit = _.cloneDeep(item)
      if (this.edit.limit_type === 0) {
        this.edit.limit_amount = null
        this.edit.limit_num = null
      } else if (this.edit.limit_type === 1) {
        this.edit.limit_amount = item.limit_amount
        this.edit.limit_num = null
      } else {
        this.edit.limit_amount = null
        this.edit.limit_num = item.limit_amount
      }
    },
    async setOk() {
      try {
        if (this.check(this.edit.integral_amount)) {
          this.$message.error('请输入正确的抵现规则积分')
          return
        }
        if (this.edit.order_type === 1 && this.checkNumber(this.edit.order_amount)) {
          this.$message.error('请输入正确的订单门槛金额')
          return
        }
        if (this.edit.limit_type === 1 && this.checkNumber(this.edit.limit_amount)) {
          this.$message.error('请输入正确的抵用限制比例')
          return
        }
        if (this.edit.limit_type === 2 && this.checkNumber(this.edit.limit_num)) {
          this.$message.error('请输入正确的抵用限制金额')
          return
        }
        await service.editRecycle({
          ...this.edit,
          integral_amount: Number(this.edit.integral_amount),
          order_amount: Number(this.edit.order_amount),
          limit_amount:
            this.edit.limit_type === 1
              ? Number(this.edit.limit_amount)
              : this.edit.limit_type === 2
              ? Number(this.edit.limit_num)
              : 0,
        })
        this.$message.success('修改成功')
        this.setCancel()
        this.getList()
      } catch (e) {}
    },
    setCancel() {
      this.visible.set = false
    },
    onChange() {
      //
    },
    checkNumber(e) {
      const reg = /^[0-9]+.?[0-9]*$/
      if (reg.test(e)) {
        return false
      }
      return true
    },
    // 正整数
    check(e) {
      const reg = /^\d+$/
      if (reg.test(e)) {
        return false
      }
      return true
    },
  },
}
</script>

<style lang="less" module>
.home {
  min-height: calc(100vh - 260px);

  .table {
    .title {
      margin: 4px 0 14px 0;
      color: #000;
      font: 14px;
    }
  }
}

.modal {
  :global {
    .ant-form-item {
      margin-bottom: 0;
    }

    .ant-form label {
      display: flex;
      align-items: center;
      margin-bottom: 10px;
    }
  }
}
</style>
